.clickable-row {
    cursor: pointer; /* Change cursor to pointer to indicate that the row is clickable */
}

.clickable-row input[type="radio"] {
    margin-right: 0; /* Optional: Remove margin on the right side of the radio button */
}

/* For small devices */
@media (max-width: 576px) {
    .container {
        padding: 15px;
    }

    /* Ensure images don't overflow */
    img {
        max-width: 100%;
        height: auto;
    }

    h1, h3, h2, h5 {
        font-size: 1.2rem;
        text-align: center;
    }

    .btn {
        width: 100%;
    }

    footer {
        font-size: 14px;
    }

    footer h5 {
        font-size: 16px;
    }
}
