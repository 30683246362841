.fullscreen-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
}

.calendar-container {
  width: 100%;
  max-width: 600px;
  /* Adjust this based on the desired size */
  text-align: center;
}